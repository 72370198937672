import React, { useState, useEffect } from "react";
import { Card, CardBody, CardTitle, CardText, Col } from "reactstrap";

const DashboardCard = ({ title, color, icon, number }) => {
  const [displayedNumber, setDisplayedNumber] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayedNumber(number);
    }, 500); // Adjust the delay according to your preference

    return () => clearTimeout(timer);
  }, [number]);
  
  return (
    <Col lg={3} md={6}>
     <Card className={`bg-white rounded`} style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}>
        <CardBody>
          <CardTitle className="text-dark mb-4" style={{ fontSize: "1.5rem" }}>
            <i className={`mdi mdi-${icon} mr-2`}></i>
            {title}
          </CardTitle>
          <CardText className="text-dark" style={{ fontSize: "1.2rem" }}>
            <span className={`text-${color}`} style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            {displayedNumber}
            </span>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DashboardCard;
