import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormGroup,
  Alert,
} from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import "../../global.css";
import AWSS3Uploader from "../../components/Common/S3uploader";
import {
  AddCategory,
  GetCategories,
  DeleteCategory,
  UpdateCategory,
} from "../../requests";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toast } from 'react-toastify';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Category = () => {
  document.title = "Category | Susipizza";

  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [categoryData,setcategoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
    
      const resp = await AddCategory(token, {
        category: category,
        image: image,
      });
      // console.log(resp);
      toast.success('Category added successfully');
      toggle();
      getData()
    } catch (err) {
      // console.log(err);
      toast.error('Error occurred while saving category');
    } finally {
      setCategory("");
      setImage(null);
      setLoading(false);

    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const resp = await GetCategories();
      
      setcategoryData(resp.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row, index) => (index + 1).toString(),
      // sortable: true
    },

    {
      name: <span className="font-weight-bold fs-13">Image</span>,
      cell: (row) => (
        <img src={row.image} alt="User Photo" width="50" height="50" />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Category</span>,
      selector: (row) => row.category,
      // sortable: true
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      // sortable: true,
      cell: (row) => {
        const handleRemove = async() => {
          try{
            setLoading(true)
            const token=localStorage.getItem('token')
            const resp=await DeleteCategory(token,row.id)
            toast.success('Category removed successfully');

          }catch{
            // console.log(err)
            toast.error('Error occurred while removing category');
          }finally{
            setLoading(false)
            getData()
          }
        };
  
        return <button className="btn btn-outline-danger" onClick={handleRemove}>Remove</button>;
      },
    },
  ];

  

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading}/>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Category" />

          <button type="button" class="btn btn-primary mb-3" onClick={toggle}>
            Add Category
          </button>
          <AlternativePagination columns={columns} data={categoryData} />
        </Container>
      </div>

      {/* Modal */}

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Category</ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="exampleEmail">Image</Label>
            <AWSS3Uploader onUploadComplete={(url) => setImage(url)} />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Category</Label>
            <Input
              id="exampleEmail"
              name="category"
              placeholder="Add"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Category;
