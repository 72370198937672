import React,{useState,useEffect} from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Container, Row,Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DashboardCard from "../../components/Common/DashboardCard";
import { useSelector } from "react-redux";
import { GetDashboardData,GetDashboardDataByUserName } from "../../requests";


const Dashboard = () => {
  document.title = "Dashboard | Susipizza";
  const role = useSelector(state => state.login.role);
  const user=useSelector(state => state.login.user);

  const [Loading,setLoading]=useState(false)
  const [DashData,setDashData]=useState({})

  const cardsData = [
    { title: "Incoming Orders", color: "primary", icon: "cart-plus", number: DashData?.totalOrderedOrders },
    { title: "Accepted Orders", color: "success", icon: "check-circle", number: DashData?.totalPreparingOrders },
    { title: "Out for Delivery", color: "info", icon: "truck-delivery", number: DashData?.totalOnTheWayOrders },
    { title: "Orders Completed", color: "warning", icon: "check-all", number: DashData?.totalDeliveredOrders }
  ];

  const additionalRows = [
    { title: "Total Menu Items", icon: "food", number: DashData?.totalMenuItems, color: "green" },
    { title: "Delivery Boys", icon: "moped", number: DashData?.totalDeliveryBoys, color: "indigo" },
    { title: "Total Sales", icon: "cash-check", number: `₹ ${DashData?.totalSales}`, color: "yellow" }
  ];
  
  if (role !== "admin") {
    additionalRows.unshift({ title: "Total Stores", icon: "store", number: DashData.totalStores, color: "pink" });
  }

  const Data=async()=>{
    setLoading(true)
    try{
      if(role==="admin"){
        const response=await GetDashboardDataByUserName(user);

        setDashData(response)
      }else{
        const response=await GetDashboardData();
        setDashData(response)
      }
  
    
    }catch(err){
      console.log(err)
    }finally{
      setLoading(false)
    }
  }

  useEffect(()=>{
Data()
  },[])

  const handleRefresh = () => {
    Data();
  };

  return (
    <React.Fragment>
      <LoadingSpinner loading={Loading}/>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Susipizza" breadcrumbItem="Dashboard" />
        
            <Button color="primary" style={{marginBottom:'10px'}} onClick={handleRefresh}>
              Refresh
            </Button>
         
          <Row>
            {cardsData.map((card, index) => (
              <DashboardCard
                key={index}
                title={card.title}
                color={card.color}
                icon={card.icon}
                number={card.number}
              />
            ))}
          </Row>
          <Row className="mt-4">
            {additionalRows.map((row, index) => (
              <DashboardCard
                key={index}
                color={row.color}
                title={row.title}
                icon={row.icon}
                number={row.number}
              />
            ))}
          </Row>
         
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
