import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./reducers";
import rootSaga from "./sagas";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export function configureStore(initialState) {

  const store = createStore(
    // initialState,
    rootReducer,
    loadState(), 
      composeEnhancers(
          applyMiddleware(...middlewares)
      ),
  );


  sagaMiddleware.run(rootSaga);
    // Save state to local storage on store update
    store.subscribe(() => {
      saveState({
        login: {
          user: store.getState().login.user,
          role: store.getState().login.role,
        },
      });
    });
  return store
}


function loadState() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

// Save state to local storage
function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
    // Ignore write errors
  }
}

