import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormGroup,
  Alert,
} from "reactstrap";
import { toast } from 'react-toastify';
import { AlternativePagination } from "../../components/Common/Table";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { AddBanner, DeleteBanner, GetBanners } from "../../requests";
import AWSS3Uploader from "../../components/Common/S3uploader";
import LoadingSpinner from "../../components/LoadingSpinner";

const Banners = () => {
  document.title = "Banners | Susipizza";

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [BannersData, setBanners] = useState([]);
  const toggle = () => setModal(!modal);
  

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      const resp = await AddBanner(token, {
        imageUrl: image,
      });
      // console.log(resp);
      toast.success("Banner added successfully");
      toggle();
      getData();
    } catch (err) {
      // console.log(err);
      toast.error("Error occurred while saving Banner");
    } finally {
      setImage(null);
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const resp = await GetBanners();

      setBanners(resp.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row, index) => (index + 1).toString(),
      // sortable: true
    },
    {
      name: <span className="font-weight-bold fs-13">Image</span>,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={row.imageUrl}
            alt="image"
            style={{ width: "140px", height: "100px" }}
          />
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      // sortable: true,
      cell: (row) => {
          const handleRemove = async() => {
            try{
              setLoading(true)
              const token=localStorage.getItem('token')
              const resp=await DeleteBanner(token,row.id)
              toast.success('Banner removed successfully');

            }catch{
              // console.log(err)
              toast.error('Error occurred while removing category');
            }finally{
              setLoading(false)
              getData()
            }
          };

        return <button className="btn btn-outline-danger" onClick={handleRemove}>Remove</button>;
      },
    },
  ];

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Banners" />
          <button
            type="button"
            class="btn btn-primary mb-3"
            onClick={() => {
              setModal(true);
             
            }}
          >
            Add Banner
          </button>
          <AlternativePagination columns={columns} data={BannersData} />
        </Container>
      </div>

      {/* Modal */}

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Category</ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="exampleEmail">Image</Label>
            <AWSS3Uploader onUploadComplete={(url) => setImage(url)} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Banners;
