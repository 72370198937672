import React, { useEffect, useState } from "react";
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormGroup,
  Badge,
  Col,
  Row,
} from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import "../../global.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AWSS3Uploader from "../../components/Common/S3uploader";
import {
  AddStores,
  DeleteStores,
  UpdateStores,
  GetStores,
} from "../../requests";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toast } from "react-toastify";

const Stores = () => {
  document.title = "Stores | Susipizza";

  const [modal, setModal] = useState(false);
  const [storeImage, setStoreImage] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [description, setDescription] = useState("");
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [deliveryRadius, setDeliveryRadius] = useState();
  const [status, setStatus] = useState(""); // Default status is 'active'
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [StoresData, setStoresData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ButtonText, setButtonText] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const toggle = () => setModal(!modal);

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row, index) => (index + 1).toString(),
    },
    {
      name: <span className="font-weight-bold fs-13">StoreImage</span>,
      cell: (row) => (
        <img src={row.storeImage} alt="User Photo" width="50" height="50" />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Username</span>,
      selector: (row) => row.username,
    },
    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.name}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.address}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Phone No.</span>,
      selector: (row) => row.phoneNumber,
    },
    {
      name: <span className="font-weight-bold fs-13">Alternate Phone</span>,
      selector: (row) => row.alternatePhoneNumber,
    },
    {
      name: <span className="font-weight-bold fs-13">Delivery Radius</span>,
      selector: (row) => row.deliveryRadius,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => (
        <Badge color={row.status === "active" ? "success" : "danger"} pill>
          {row.status}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => (
        <>
          <div className="buttons">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleEdit(row)}
            >
              <span className="mdi mdi-pencil icon-lg"></span>
            </button>
            <button
              className="btn btn-outline-danger mr-2"
              onClick={() => handleDelete(row.id)}
            >
              <span className="mdi mdi-delete icon-lg"></span>
            </button>
          </div>
        </>
      ),
    },
  ];

  const handleDelete = async (storeId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const resp = await DeleteStores(token, storeId);
      console.log("Store deleted successfully");
      GetStoresData();
      toast.success("Store Deleted successfully");
    } catch (err) {
      // console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const GetStoresData = async () => {
    try {
      setLoading(true);
      const resp = await GetStores();

      setStoresData(resp.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetStoresData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const payload = {
        name: name,
        address: address,
        phoneNumber: phone,
        alternatePhoneNumber: alternatePhone,
        storeImage: storeImage,
        description: description,
        openingTime: openingTime,
        closingTime: closingTime,
        latitude: parseFloat(latitude), // Convert latitude to number
        longitude: parseFloat(longitude), // Convert longitude to number
        deliveryRadius: parseFloat(deliveryRadius),
        username: loginUsername,
        status: status,
        password: loginPassword,
      };

      if (ButtonText === "Update") {
        // Update existing data
        const resp = await UpdateStores(token, selectedStoreId, payload);
        toast.success("Store updated successfully");
      } else {
        // Add new data
        const resp = await AddStores(token, payload);
        toast.success("Store added successfully");
      }

      toggle();
      setName("");
      setAddress("");
      setPhone("");
      setAlternatePhone("");
      setStoreImage("");
      setDescription("");
      setOpeningTime("");
      setClosingTime("");
      setLatitude("");
      setLongitude("");
      setDeliveryRadius("");
      setLoginUsername("");
      setStatus("");
      setLoginPassword("");
      setSelectedStoreId(null);
      GetStoresData();
    } catch (err) {
      // console.log(err)
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (rowData) => {
    console.log("Edit", rowData);
    setButtonText("Update");
    setModal(true);

    // Set the state values with the data of the selected row
    setName(rowData.name);
    setAddress(rowData.address);
    setPhone(rowData.phoneNumber);
    setAlternatePhone(rowData.alternatePhoneNumber);
    setStoreImage(rowData.storeImage);
    setDescription(rowData.description);
    setOpeningTime(rowData.openingTime);
    setClosingTime(rowData.closingTime);
    setLatitude(rowData.latitude.toString());
    setLongitude(rowData.longitude.toString());
    setDeliveryRadius(rowData.deliveryRadius.toString());
    setStatus(rowData.status);
    setLoginUsername(rowData.username);
    setSelectedStoreId(rowData.id);
    // Password should not be set here for security reasons, you may choose to handle it differently
  };

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Stores" />
          <button
            type="button"
            class="btn btn-primary mb-3"
            onClick={() => {
              setModal(true);
              setButtonText("Register");
            }}
          >
            Add Store
          </button>
          <AlternativePagination columns={columns} data={StoresData} />
        </Container>
      </div>

      {/* Modal */}

      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Add Restaurant</ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="storeImage">Store Image</Label>

            <img
              src={storeImage}
              alt="Store"
              className="img-fluid"
              style={{ width: "100px", height: "100px" }}
            />

            <AWSS3Uploader onUploadComplete={(e) => setStoreImage(e)} />
            {/* <Input
              type="text"
              id="storeImage"
              value={storeImage}
              onChange={(e) => setStoreImage(e.target.value)}
            /> */}
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="address">Address</Label>
                <Input
                  type="text"
                  id="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">Phone Number</Label>
                <Input
                  type="text"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="alternatePhone">Alternate Phone</Label>
                <Input
                  type="text"
                  id="alternatePhone"
                  value={alternatePhone}
                  onChange={(e) => setAlternatePhone(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="openingTime">Opening Time</Label>
                <Input
                  type="time"
                  id="openingTime"
                  value={openingTime}
                  onChange={(e) => setOpeningTime(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="closingTime">Closing Time</Label>
                <Input
                  type="time"
                  id="closingTime"
                  value={closingTime}
                  onChange={(e) => setClosingTime(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="latitude">Latitude</Label>
                <Input
                  type="text"
                  id="latitude"
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="longitude">Longitude</Label>
                <Input
                  type="text"
                  id="longitude"
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="deliveryRadius">Delivery Radius (KM)</Label>
            <Input
              type="text"
              id="deliveryRadius"
              value={deliveryRadius}
              onChange={(e) => setDeliveryRadius(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="statusStore">Status</Label>
            <Input
              type="select"
              id="statusStore"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="loginUsername">Login Username</Label>
            <Input
              type="text"
              id="loginUsername"
              value={loginUsername}
              onChange={(e) => setLoginUsername(e.target.value)}
            />
          </FormGroup>
          {ButtonText !== "Update" && (
            <FormGroup>
              <Label for="loginPassword">Login Password</Label>
              <Input
                type="password"
                id="loginPassword"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            </FormGroup>
          )}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {ButtonText}
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Stores;
