import React, { useState } from 'react';
import { S3 } from 'aws-sdk';
import { Button } from 'reactstrap';
import './awss3.css';
import { Alert } from 'reactstrap';
import { Spinner } from 'reactstrap';

const AWSS3Uploader = ({ onUploadComplete }) => {
    const [file, setFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    const AWS_DEFAULT_REGION = "ap-south-1";
    
    // Set AWS credentials for the AWS SDK
    const awsCredentials = {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
        region: AWS_DEFAULT_REGION,
    };
    
    const uploadToS3 = async (file) => {
        const s3 = new S3(awsCredentials);
        const params = {
            Bucket: "susipizza",
            Key: `${file.name}`,
            Body: file,
            ContentType: file.type,
            ContentDisposition: "inline",
        };
        
        try {
            setUploading(true);
            // Upload the file to S3
            const result = await s3.upload(params).promise();
            // Generate the object URL with the correct region
            const objectUrl = `https://${params.Bucket}.s3.${awsCredentials.region}.amazonaws.com/${params.Key}`;
            // Call the callback function with the uploaded URL
            onUploadComplete(objectUrl);
            setUploadMessage({ color: 'success', message: 'Upload Successful', detail: 'Image uploaded successfully!' });
        } catch (error) {
            console.error(`Error uploading file ${file.name} to S3:`, error);
            // Or handle the error as needed
        } finally {
            setUploading(false);
        }
    };
    
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };
    
    const handleUpload = () => {
        if (file) {
            uploadToS3(file);
        }
    };
    
    return (
        <div className='uploadAWS'>
            <input type="file" onChange={handleFileChange} />
            <Button
                color="success"
                onClick={handleUpload}
                disabled={!file}
            >
                Upload
            </Button>
            <div className='loading'>
                {uploading && <Spinner style={{ width: '30px', height: '30px' }} color="primary" />}
                {uploadMessage && (
                    <Alert
                        color={uploadMessage.color}
                        isOpen={true}
                        toggle={() => setUploadMessage(null)}
                    >
                        <strong>{uploadMessage.message}</strong> 
                    </Alert>
                )}
            </div>
        </div>
    );
};

export default AWSS3Uploader;