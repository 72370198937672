import React, { useEffect, useState } from "react";
import {
  Container,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  GetAllOrders,
  StatusUpdate,
  GetAvailableDeliveryPart,
  GetAvailableDeliverybyStore,
  AssignDeliveryBoy,
  GetOrdersByUserName,
} from "../../requests";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";

// const functions = require('firebase-functions');
// const admin = require('firebase-admin');
// const axios = require('axios');

const Orders = () => {
  document.title = "Orders | Susipizza";

  const [ordersDetails, setordersDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [AvailableDeliveryBoys, setAvailableDeliveryBoys] = useState([]);
  const [selectedDeliveryBoyName, setSelectedDeliveryBoyName] = useState("");
  const [selectedDeliveryBoyPhoneNumber, setSelectedDeliveryBoyPhoneNumber] =
    useState("");
  const userRole = useSelector((state) => state?.login?.role);
  const username = useSelector((state) => state?.login?.user);

  const toggleModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(!modalOpen);
  };

  // ==================

  //   const functions = require('firebase-functions');
  // const admin = require('firebase-admin');
  // const axios = require('axios');

  // admin.initializeApp();

  // exports.notifyNewOrder = functions.firestore
  //   .document('orders/{orderId}')
  //   .onCreate(async (snapshot, context) => {
  //     try {
  //       const newOrder = snapshot.data();

  //       // Make HTTP request to your API to notify about the new order
  //       const response = await axios.post('YOUR_API_ENDPOINT', newOrder);

  //       console.log('API Response:', response.data);

  //       return null;
  //     } catch (error) {
  //       console.error('Error notifying about new order:', error);
  //       return null;
  //     }
  //   });

  const handleAssign = async () => {
    setLoading(true)
    try {
      const token = localStorage.getItem("token");
      if (
        selectedDeliveryBoyName === "" ||
        selectedDeliveryBoyPhoneNumber === ""
      )
        return toast.error("Please select delivery boy");
      const payload = {
        deliveryBoy: {
          name: selectedDeliveryBoyName,
          phoneNumber: selectedDeliveryBoyPhoneNumber,
        },
      };
      const resp = AssignDeliveryBoy(token, selectedOrder, payload);

      setModalOpen(false);
      setSelectedDeliveryBoyName("");
      setSelectedDeliveryBoyPhoneNumber("");
    } catch (err) {
      console.log(err);
    }finally{
      setLoading(false)
    }
  };

  const columns = [
    // {
    //   name: <span className="font-weight-bold fs-13">SR No.</span>,
    //   selector: (row, index) => (index + 1).toString(),
    // },
    {
      name: <span className="font-weight-bold fs-13">OrderId</span>,
      selector: (row) => row.orderID,
    },

    {
      name: <span className="font-weight-bold fs-13">Store</span>,
      selector: (row) => row?.store?.name,
    },
    {
      name: <span className="font-weight-bold fs-13">Ordered at</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.orderedAt}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Ordered by</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.customerName}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Item(s)</span>,
      cell: (row) => (
        <ul>
          {row.items.map((item, index) => (
            <li key={index}>
              {item.name} ({item.quantity})
            </li>
          ))}
        </ul>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.customerAddress}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Method</span>,
      cell: (row) => {
        const color = row.method === "delivery" ? "primary" : "light";
        return (
          <Badge color={color} pill>
            {row.method}
          </Badge>
        );
      },
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Delivery by</span>,
    //   selector: (row) => row.deliveryBoy,
    // },
    {
      name: <span className="font-weight-bold fs-13">Price Paid (₹)</span>,
      selector: (row) => row.totalprice,
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => {
        let color;
        switch (row.status) {
          case "ordered":
            color = "danger";
            break;
          case "delivered":
            color = "success";
            break;
          case "preparing":
            color = "warning";
            break;
          case "On the way":
            color = "info";
            break;
          case "rejected":
            color = "dark";
            break;
          default:
            color = "secondary";
        }
        return (
          <Badge color={color} pill>
            {row.status}
          </Badge>
        );
      },
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (row) => {
        if (row.status === "ordered") {
          return (
            <>
              <div className="buttons">
                <Button
                  color="success"
                  size="sm"
                  className="custom-btn"
                  onClick={() => {
                    UpdateStatus(row.id, { status: "preparing" });
                  }}
                >
                  Accept
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  className="custom-btn"
                  onClick={() => {
                    UpdateStatus(row.id, { status: "rejected" });
                  }}
                >
                  Reject
                </Button>
              </div>
            </>
          );
        } else if (row.status === "preparing") {
          return (
            <>
              {row.method === "delivery" ? (
                <Button
                  color="warning"
                  size="sm"
                  className="custom-btn"
                  onClick={() => toggleModal(row.id)}
                >
                  Assign
                </Button>
              ) : (
                <Button
                  color="success"
                  size="sm"
                  className="custom-btn"
                  onClick={() => {
                    UpdateStatus(row.id, { status: "delivered" });
                  }}
                >
                  Deliver
                </Button>
              )}
            </>
          );
        } else {
          // For any other status, no actions
          return null;
        }
      },
    },
  ];

  const GetOrders = async () => {
    setLoading(true);
    try {
      if (userRole === "admin") {
        const resp = await GetOrdersByUserName(username);
        setordersDetails(resp.data);
      } else {
        const resp = await GetAllOrders();
        setordersDetails(resp.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    GetOrders()
  },[])

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     GetOrders();
  //   }, 5000);

  //   // Clean up the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  const UpdateStatus = async (id, data) => {
    try {
      const token = localStorage.getItem("token");
      const resp = await StatusUpdate(id, data, token);
      // console.log(resp);
      GetOrders();
    } catch (err) {
      console.log(err);
    }
  };

  const GetDeliverBoys = async () => {
    try {
      if (userRole === "admin") {
        const resp = await GetAvailableDeliverybyStore(username);
        setAvailableDeliveryBoys(resp.data);
      } else {
        const resp = await GetAvailableDeliveryPart();

        setAvailableDeliveryBoys(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetDeliverBoys();
  }, []);

  const handleDeliveryBoyChange = (e) => {
    const selectedDeliveryBoyData = JSON.parse(e.target.value);
    const { name, phoneNumber } = selectedDeliveryBoyData;

    setSelectedDeliveryBoyName(name);
    setSelectedDeliveryBoyPhoneNumber(phoneNumber);
  };

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Orders" />
        <Button color="primary" onClick={GetOrders} className="mb-4">Refresh Orders</Button>
          <AlternativePagination columns={columns} data={ordersDetails} />

          <Modal isOpen={modalOpen} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Assign Delivery</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="deliveryBoy">Select Delivery Person</Label>
                <Input
                  type="select"
                  name="deliveryBoy"
                  id="deliveryBoy"
                  onChange={handleDeliveryBoyChange}
                  placeholder="Select Delivery Boy"
                  // defaultValue=""
                >
                  <option value="">Select Delivery Boy</option>
                  {AvailableDeliveryBoys.map((data, index) => (
                    <option key={index} value={JSON.stringify(data)}>
                      {data.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleAssign}>
                Assign
              </Button>{" "}
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Orders;
