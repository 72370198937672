import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const LoginUserapi = async (data) => {
  const response = await axios.post(`${BASE_URL}/auth/login`, data);
  return response;
};

const AddCategory = async (token, data) => {
  const response = await axios.post(`${BASE_URL}/category/add`, data, {
    headers: { Authorization: token },
  });

  return response;
};

const DeleteCategory = async (token, id) => {
  const response = await axios.delete(`${BASE_URL}/category/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const UpdateCategory = async (token, id, data) => {
  const response = await axios.put(`${BASE_URL}/category/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const GetCategories = async () => {
  const response = await axios.get(`${BASE_URL}/category`);
  return response;
};

const AddDeliveryBoy = async (token, data) => {
  const response = await axios.post(`${BASE_URL}/delivery/add`, data, {
    headers: { Authorization: token },
  });
  return response;
};

const DeleteDeliveryBoy = async (token, id) => {
  const response = await axios.delete(`${BASE_URL}/delivery/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const UpdateDeliveryBoy = async (token, id, data) => {
  const response = await axios.put(`${BASE_URL}/delivery/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const GetDeliveryBoys = async () => {
  const response = await axios.get(`${BASE_URL}/delivery`);
  return response;
};

const GetDeliveryBoysByUserName = async (username) => {
  const response = await axios.get(
    `${BASE_URL}/delivery/getDeliveryBoys/${username}`
  );
  return response;
};

const AddStores = async (token, data) => {
  const response = await axios.post(`${BASE_URL}/store/add`, data, {
    headers: { Authorization: token },
  });
  return response;
};

const DeleteStores = async (token, id) => {
  const response = await axios.delete(`${BASE_URL}/store/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const UpdateStores = async (token, id, data) => {
  const response = await axios.put(`${BASE_URL}/store/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};
const GetStores = async () => {
  const response = await axios.get(`${BASE_URL}/store`);
  return response;
};

const AddMenu = async (token, data) => {
  const response = await axios.post(`${BASE_URL}/menu/add`, data, {
    headers: { Authorization: token },
  });
  return response;
};

const DeleteMenu = async (token, id) => {
  const response = await axios.delete(`${BASE_URL}/menu/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const UpdateMenu = async (token, id, data) => {
  const response = await axios.put(`${BASE_URL}/menu/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};
const GetMenu = async () => {
  const response = await axios.get(`${BASE_URL}/menu`);
  return response;
};

const GetMenuByUserName = async (username) => {
  const response = await axios.get(`${BASE_URL}/menu/store/${username}`);
  return response;
};

const AddCoupons = async (token, data) => {
  const response = await axios.post(`${BASE_URL}/coupons/add`, data, {
    headers: { Authorization: token },
  });
  return response;
};

const UpdateCoupons = async (token, id, data) => {
  const response = await axios.put(`${BASE_URL}/coupons/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const DeleteCoupons = async (token, id) => {
  const response = await axios.delete(`${BASE_URL}/coupons/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response;
};

const GetCoupons = async () => {
  const response = await axios.get(`${BASE_URL}/coupons`);
  return response;
};

const GetDashboardData = async () => {
  const response = await axios.get(`${BASE_URL}/dashboard/data`);
  return response;
};

const GetDashboardDataByUserName = async (username) => {
  const response = await axios.get(`${BASE_URL}/dashboard/data/${username}`);
  return response;
};

const GetAllUsers = async () => {
  const response = await axios.get(`${BASE_URL}/customer/all`);
  return response;
};

const GetAllOrders = async () => {
  const response = await axios.get(`${BASE_URL}/order`);
  return response;
};

const StatusUpdate = async (id, data, token) => {
  const response = await axios.put(`${BASE_URL}/order/status/${id}`, data, {
    headers: { Authorization: token },
  });
  return response;
};

const GetAvailableDeliveryPart = async () => {
  const response = await axios.get(`${BASE_URL}/delivery/available`);
  return response;
};

const GetAvailableDeliverybyStore = async (storename) => {
  const response = await axios.get(
    `${BASE_URL}/delivery/getAvailableDeliveryBoys/${storename}`
  );
  return response;
};

const AssignDeliveryBoy = async (token, id, data) => {
  const response = await axios.post(
    `${BASE_URL}/order/assign-delivery-boy/${id}`,
    data,
    { headers: { Authorization: token } }
  );
  return response;
};


const GetOrdersByUserName = async (username) => {
  const response = await axios.get(`${BASE_URL}/order/getorders/store?storeUsername=${username}`);
  return response;
}

const AddBanner= async (token, data) => {
  const response = await axios.post(`${BASE_URL}/banners/add`, data, {
    headers: { Authorization: token },
  });
  return response;
}

const DeleteBanner= async (token, id) => {
  const response = await axios.delete(`${BASE_URL}/banners/delete/${id}`, {
    headers: {
      Authorization: token,
    },
  });
  return response;
}

const GetBanners= async () => {
  const response = await axios.get(`${BASE_URL}/banners`);
  return response;
}

const ChangeRiderPassword=async(token,data)=>{
  const response=await axios.put(`${BASE_URL}/delivery/user/changePassword`,data, {
    headers: {
      Authorization: token,
    },
  })
  return response
}

export {
  LoginUserapi,
  AddCategory,
  DeleteCategory,
  UpdateCategory,
  GetCategories,
  AddDeliveryBoy,
  DeleteDeliveryBoy,
  UpdateDeliveryBoy,
  GetDeliveryBoysByUserName,
  GetDeliveryBoys,
  AddStores,
  DeleteStores,
  UpdateStores,
  GetStores,
  AddMenu,
  DeleteMenu,
  UpdateMenu,
  GetMenu,
  AddCoupons,
  UpdateCoupons,
  DeleteCoupons,
  GetCoupons,
  GetMenuByUserName,
  GetDashboardData,
  GetDashboardDataByUserName,
  GetAllUsers,
  GetAllOrders,
  StatusUpdate,
  GetAvailableDeliveryPart,
  GetAvailableDeliverybyStore,
  AssignDeliveryBoy,
  GetOrdersByUserName,
  AddBanner,
  DeleteBanner,
  GetBanners,
  ChangeRiderPassword
};
