import React, { useEffect, useState } from "react";
import {
  Container,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import "../../global.css";
import AWSS3Uploader from "../../components/Common/S3uploader";
import {
  AddDeliveryBoy,
  DeleteDeliveryBoy,
  UpdateDeliveryBoy,
  GetDeliveryBoys,
  GetStores,
  GetDeliveryBoysByUserName,
  ChangeRiderPassword,
} from "../../requests";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Delivery = () => {
  document.title = "Delivery | Susipizza";

  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [idProofPhoto, setIdProofPhoto] = useState("");
  const [selectedStore, setSelectedStore] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [availability, setAvalibility] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [ButtonText, setButtonText] = useState("");
  const [StoresData, setStoresData] = useState([]);
  const [DeliveryData, setDeliveryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(null);
  const userRole = useSelector((state) => state?.login?.role);
  const usernameAd = useSelector((state) => state?.login?.user);

  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const toggle = () => {
    setisModalVisible(!isModalVisible);

    // Reset all state values to their initial state
    setName("");
    setPhoto("");
    setPhoneNumber("");
    setAddress("");
    setIdProofPhoto("");
    setSelectedStore([]);
    setUsername("");
    setPassword("");
    setAvalibility(false);
    setButtonText("");
  };

  const togglePasswordModal = () => {
    setIsPasswordModalVisible(!isPasswordModalVisible);
    setOldPassword("");
    setNewPassword("");
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row, index) => (index + 1).toString(),
      // sortable: true
    },

    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.name}
        </div>
      ),
      // sortable: true
    },
    {
      name: <span className="font-weight-bold fs-13">Photo</span>,
      cell: (row) => (
        <img src={row.photo} alt="User Photo" width="50" height="50" />
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">Phone No.</span>,
      selector: (row) => row.phoneNumber,
      // sortable: true
    },
    {
      name: <span className="font-weight-bold fs-13">Address</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.address}
        </div>
      ),
      // sortable: true
    },
    {
      name: <span className="font-weight-bold fs-13">Store</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.store}
        </div>
      ),
      // sortable: true
    },

    {
      name: <span className="font-weight-bold fs-13">Availability</span>,
      cell: (row) => (
        <Badge
          color={row.availability === "Available" ? "success" : "danger"}
          pill
        >
          {row.availability}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => (
        <>
          <div className="buttons">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleEdit(row)}
            >
              <span className="mdi mdi-pencil icon-lg"></span>
            </button>
            <button
              className="btn btn-outline-danger mr-2"
              onClick={() => handleDelete(row.id)}
            >
              <span className="mdi mdi-delete icon-lg"></span>
            </button>
          </div>
        </>
      ),
    },
  ];

  const handleDelete = async (storeId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const resp = await DeleteDeliveryBoy(token, storeId);

      DeliveryBoysData();
      toast.success("Delivery Boy Deleted successfully");
    } catch (err) {
      // console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const payload = {
        name: name,
        photo: photo,
        idProof: idProofPhoto,
        phoneNumber: phoneNumber,
        address: address,
        store: selectedStore,
        availability: availability,
        username: username,
        password: password,
      };

      if (ButtonText === "Update") {
        const resp = await UpdateDeliveryBoy(
          token,
          selectedDeliveryId,
          payload
        );
        toast.success("Delivery Boy Updated successfully");
      } else {
        const resp = await AddDeliveryBoy(token, payload);
        toast.success("Delivery Boy Added successfully");
      }

      toggle();
      setName("");
      setPhoto("");
      setIdProofPhoto("");
      setPhoneNumber("");
      setAddress("");
      setSelectedStore([]);
      setAvalibility("");
      setUsername("");
      setPassword("");
      DeliveryBoysData();
    } catch (err) {
      // console.log(err)
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (rowData) => {
    checkAdmin();
    setButtonText("Update");
    setisModalVisible(true);
    setSelectedDeliveryId(rowData.id);

    // Set the state values with the data of the selected row
    setName(rowData.name);
    setPhoto(rowData.photo);
    setPhoneNumber(rowData.phoneNumber);
    setAddress(rowData.address);
    setIdProofPhoto(rowData.idProof);
    setSelectedStore(rowData.store);
    setUsername(rowData.username);

    setAvalibility(rowData.availability);
  };

  const GetDataStores = async () => {
    try {
      setLoading(true);
      const resp = await GetStores();

      setStoresData(resp.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const DeliveryBoysData = async () => {
    try {
      setLoading(true);
      if (userRole === "admin") {
        const resp = await GetDeliveryBoysByUserName(usernameAd);

        setDeliveryData(resp.data);
      } else {
        const resp = await GetDeliveryBoys();
        setDeliveryData(resp.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetDataStores();
    DeliveryBoysData();
  }, []);

  const getStoreName = (username) => {
    const stored = StoresData.find((storex) => storex.username === username);
    return stored ? [stored.name] : [];
  };

  const checkAdmin = () => {
    if (userRole === "admin") {
      const selectedStore = getStoreName(usernameAd);
      setSelectedStore(selectedStore);
    }
  };

  useEffect(() => {
    checkAdmin();
  }, [userRole, usernameAd]);

  const handleChangePassword = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const payload = {
        username: username, // Use the username from the selected delivery boy
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
     
      const resp = await ChangeRiderPassword(token, payload);
      togglePasswordModal()
      toast.success("Password changed successfully");
    } catch (err) {
      console.error("Error changing password:", err);
      toast.error("Something went wrong check the password entered");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Delivery" />
          <button
            type="button"
            class="btn btn-primary mb-3"
            onClick={() => {
              setisModalVisible(true);
              setButtonText("Register");
              checkAdmin();
            }}
          >
            Add Delivery Boy
          </button>
          <AlternativePagination columns={columns} data={DeliveryData} />
        </Container>
      </div>

      {/* Modal */}

      <Modal isOpen={isModalVisible} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>
          {ButtonText === "Update" ? "Update Delivery Boy" : "Add Delivery Boy"}
        </ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="photo">Photo</Label>

            {/* <img
              src={photo}
              alt="image"
              className="img-fluid"
              style={{ width: "100px", height: "100px" }}
            /> */}
            <AWSS3Uploader onUploadComplete={(e) => setPhoto(e)} />
            {/* <Input
              type="text"
              id="photo"
              value={photo}
              onChange={(e) => setPhoto(e.target.value)}
            /> */}
          </FormGroup>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <Input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="address">Address</Label>
            <Input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="idProofPhoto">ID Proof Photo</Label>
            {/* <img
              src={idProofPhoto}
              alt="id-proof"
              className="img-fluid"
              style={{ width: "100px", height: "100px" }}
            /> */}
            <AWSS3Uploader onUploadComplete={(e) => setIdProofPhoto(e)} />
            {/* <Input
              type="text"
              id="idProofPhoto"
              value={idProofPhoto}
              onChange={(e) => setIdProofPhoto(e.target.value)}
            /> */}
          </FormGroup>
          <FormGroup>
            <Label for="selectStore">Select Store</Label>

            {userRole === "admin" ? (
              <Input
                type="text"
                id="selectStore"
                value={
                  Array.isArray(selectedStore)
                    ? selectedStore.join(", ")
                    : selectedStore
                } // Display selected stores as comma-separated string
                disabled
              />
            ) : (
              <Input
                type="select"
                id="selectStore"
                multiple
                value={selectedStore}
                onChange={(e) =>
                  setSelectedStore(
                    Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    )
                  )
                }
              >
                {StoresData.map((store) => (
                  <option key={store.id} value={store.name}>
                    {store.name}
                  </option>
                ))}
              </Input>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormGroup>
          {ButtonText !== "Update" && (
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
          )}

          {ButtonText == "Update" && (
            <Button
              color="primary"
              className="mb-4"
              onClick={togglePasswordModal}
            >
              Change Password
            </Button>
          )}

          <FormGroup>
            <Label for="availability">Availability</Label>

            <Input
              type="select"
              id="availability"
              value={availability}
              onChange={(e) => setAvalibility(e.target.value)}
            >
              <option value="">Select Availability</option>
              <option value="Available">Available</option>
              <option value="Not Available">Not Available</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {ButtonText}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isPasswordModalVisible}
        toggle={togglePasswordModal}
        centered
      >
        <ModalHeader toggle={togglePasswordModal}>Change Password</ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="oldPassword">Old Password</Label>
            <Input
              type="password"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="newPassword">New Password</Label>
            <Input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleChangePassword}>
            Change Password
          </Button>
          <Button color="secondary" onClick={togglePasswordModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Delivery;
