import React from "react";
import { Spinner } from "reactstrap";

const LoadingSpinner = ({ loading }) => {
  if (!loading) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensure it's above other elements
      }}
    >
      <Spinner color="primary" />
    </div>
  );
};

export default LoadingSpinner;
