import React, { useEffect, useState } from "react";
import {
  Container,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import "../../global.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  GetCoupons,
  AddCoupons,
  DeleteCoupons,
  UpdateCoupons,
  GetMenu
} from "../../requests";
import LoadingSpinner from "../../components/LoadingSpinner";
import { toast } from "react-toastify";

const Coupons = () => {
  document.title = "Coupons | Susipizza";

  const [code, setCode] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountPercent, setDiscountPercent] = useState();
  const [discountAmount, setDiscountAmount] = useState();
  const [minimumOrder, setMinimumOrder] = useState();
  const [maximumDiscount, setMaximumDiscount] = useState();
  const [expiryDate, setExpiryDate] = useState("");
  const [status, setStatus] = useState("");
  const [usage,setUsage]=useState("")
  const [ButtonText, setButtonText] = useState("");
  const [CouponData, setCouponData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState(null);

  const [selectedBuyItems, setSelectedBuyItems] = useState([]);
  const [selectedFreeItems, setSelectedFreeItems] = useState([]);
  const [minBuyQuantity, setMinBuyQuantity] = useState("");

  const [foodItems,setfoodItems]=useState([])

  const fetchMenu=async()=>{
    try{
      const resp=await GetMenu()
      setfoodItems(resp.data)

    }catch(err){
      console.log(err)
    }
  }



  const toggle = () => {
    setModal(!modal);
    setCode("");
    setHeading("");
    setDescription("");
    setDiscountType("");
    setDiscountPercent("");
    setDiscountAmount("");
    setMinimumOrder("");
    setMaximumDiscount("");
    setExpiryDate("");
    setStatus("");
    setUsage("");
    setSelectedBuyItems([]);
    setSelectedFreeItems([]);
    setMinBuyQuantity("")
    setSelectedCouponId(null);
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row, index) => (index + 1).toString(),
    },

    {
      name: <span className="font-weight-bold fs-13">Coupon Code</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.code}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Heading</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.heading}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Description</span>,
      selector: (row) => row.description,
    },
    {
      name: <span className="font-weight-bold fs-13">Discount Type</span>,
      selector: (row) => row.discountType,
    },
    {
      name: <span className="font-weight-bold fs-13">Expiry</span>,
      selector: (row) => row.expiryDate,
    },
    {
      name: <span className="font-weight-bold fs-13">Usage</span>,
      cell: (row) => (
        <Badge color={row.usage === "single" ? "primary" : "dark"} pill>
          {row.usage === "single" ? "Single use" : "Multi use"}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Status</span>,
      cell: (row) => (
        <Badge color={row.status === "enable" ? "success" : "danger"} pill>
          {row.status === "enable" ? "Enabled" : "Disabled"}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => (
        <>
          <div className="buttons">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleEdit(row)}
            >
              <span className="mdi mdi-pencil icon-lg"></span>
            </button>
            <button
              className="btn btn-outline-danger mr-2"
              onClick={() => handleDelete(row.id)}
            >
              <span className="mdi mdi-delete icon-lg"></span>
            </button>
          </div>
        </>
      ),
    },
  ];

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const resp = await DeleteCoupons(token, id);
      toast.success(resp?.message);
      GetAllCoupons();
    } catch (err) {
      // console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (couponData) => {
    setButtonText("Update");
    setSelectedCouponId(couponData.id);
    setCode(couponData.code);
    setHeading(couponData.heading);
    setDescription(couponData.description);
    setDiscountType(couponData.discountType);
    setDiscountPercent(couponData?.discountPercent?.toString());
    setDiscountAmount(couponData?.discountAmount?.toString());
    setMinimumOrder(couponData?.minimumOrder?.toString());
    setMaximumDiscount(couponData?.maximumDiscount?.toString());
    setExpiryDate(couponData.expiryDate);
    setStatus(couponData.status);
    setUsage(couponData.usage);

    // Handle "Buy X Get Y Free" data
    setSelectedBuyItems(couponData.buyItems || []);
    setSelectedFreeItems(couponData.freeItems || []);
    setMinBuyQuantity(couponData.minBuyQuantity?.toString() || "");
    setModal(true);
  };

  const GetAllCoupons = async () => {
    try {
      setLoading(true);
      const resp = await GetCoupons();
      // console.log(resp);
      setCouponData(resp.data);
      
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllCoupons();
    fetchMenu()
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const payload = {
        code: code,
        heading: heading,
        description: description,
        discountType: discountType,
        discountPercent: parseFloat(discountPercent),
        discountAmount: parseFloat(discountAmount),
        minimumOrder: parseFloat(minimumOrder),
        maximumDiscount: parseFloat(maximumDiscount),
        expiryDate: expiryDate,
        usage:usage,
        status: status,

        // Add Buy X Get Y Free fields
        buyItems: selectedBuyItems,
        freeItems:selectedFreeItems,
        minBuyQuantity: parseInt(minBuyQuantity, 10),
      };

      if (ButtonText === "Update") {
        const resp = await UpdateCoupons(token, selectedCouponId, payload);
        toast.success(resp?.message);
      } else {
        const resp = await AddCoupons(token, payload);
        toast.success(resp?.message);
      }

      toggle();
      GetAllCoupons();
    } catch (err) {
      // console.log(err);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Coupons" />
          <button
            type="button"
            class="btn btn-primary mb-3"
            onClick={() => {
              setModal(true);
              setButtonText("Save");
            }}
          >
            Add Coupon
          </button>
          <AlternativePagination columns={columns} data={CouponData} />
        </Container>
      </div>

      {/* Modal */}

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Coupon</ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="code">Coupon Code</Label>
            <Input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="heading">Heading</Label>
            <Input
              type="text"
              id="heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>



          <FormGroup>
            <Label for="discountType">Discount Type</Label>
            <Input
              type="select"
              id="discountType"
              value={discountType}
              onChange={(e) => setDiscountType(e.target.value)}
            >
              <option value="">Select Discount Type</option>
              <option value="percent">Percentage</option>
              <option value="fixed">Fixed Amount</option>
              <option value="buyXgetY">Buy X Get Y Free</option>
            </Input>
          </FormGroup>
          {discountType === "percent" && (
            <>
              <FormGroup>
                <Label for="discountPercent">Discount Percent</Label>
                <Input
                  type="text"
                  id="discountPercent"
                  value={discountPercent}
                  onChange={(e) => setDiscountPercent(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="minimumOrder">Minimum Order</Label>
                <Input
                  type="text"
                  id="minimumOrder"
                  value={minimumOrder}
                  onChange={(e) => setMinimumOrder(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="maximumDiscount">Maximum Discount</Label>
                <Input
                  type="text"
                  id="maximumDiscount"
                  value={maximumDiscount}
                  onChange={(e) => setMaximumDiscount(e.target.value)}
                />
              </FormGroup>
            </>
          )}
          {discountType === "fixed" && (
            <FormGroup>
              <Label for="discountAmount">Discount Amount</Label>
              <Input
                type="text"
                id="discountAmount"
                value={discountAmount}
                onChange={(e) => setDiscountAmount(e.target.value)}
              />
            </FormGroup>
          )}

          {/* ============================================================== */}

{discountType === "buyXgetY" && (
    <>
      <FormGroup>
        <Label for="minBuyQuantity">Minimum Buy Quantity</Label>
        <Input
          type="number"
          id="minBuyQuantity"
          value={minBuyQuantity}
          onChange={(e) => setMinBuyQuantity(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="selectedBuyItems">Buy Items</Label>
        <Input
          type="select"
          id="selectedBuyItems"
          multiple
          value={selectedBuyItems}
          onChange={(e) =>
            setSelectedBuyItems(
              Array.from(
                e.target.selectedOptions,
                (option) => option.value
              )
            )
          }
          
        >
          {foodItems.map(item => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="selectedFreeItems">Free Items</Label>
        <Input
          type="select"
          id="selectedFreeItems"
          multiple
          value={selectedFreeItems}
          onChange={(e) =>
            setSelectedFreeItems(
              Array.from(
                e.target.selectedOptions,
                (option) => option.value
              )
            )
          }
        >
          {foodItems.map(item => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </Input>
      </FormGroup>
    </>
  )}

  {/* =============================================================== */}

          <FormGroup>
            <Label for="expiryDate">Expiry Date</Label>
            <Input
              type="date"
              id="expiryDate"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="usageType">Usage</Label>
            <Input
              type="select"
              id="usageType"
              value={usage}
              onChange={(e) => setUsage(e.target.value)}
            >
              <option value="">Select Usage Type</option>
              <option value="single">Single use</option>
              <option value="multi">Multi use</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="statusCoupons">Status</Label>
            <Input
              type="select"
              id="statusCoupons"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Select Status</option>
              <option value="enable">Enabled</option>
              <option value="disable">Disabled</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {ButtonText}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Coupons;
