import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../Pages/Dashboard";

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";

import Stores from "../Pages/Stores";
import Category from "../Pages/Category";
import Menu from "../Pages/Menu";
import Orders from "../Pages/Orders";
import Delivery from "../Pages/Delivery";
import Coupons from "../Pages/Coupons";
import Users from "../Pages/Users";

import Banners from "../Pages/Banners";

import Error404 from "../Pages/Utility/Error404-Page";

const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  { path: "/stores", component: <Stores /> },
  { path: "/Coupons", component: <Coupons /> },
  { path: "/Category", component: <Category /> },
  { path: "/Menu", component: <Menu /> },
  { path: "/Orders", component: <Orders /> },
  { path: "/Delivery", component: <Delivery /> },
  { path: "/users", component: <Users /> },
  {path: "/banners", component: <Banners />},

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
  {path: "*", component: <Error404 />},
];

export { authProtectedRoutes, publicRoutes };
