import React, { useEffect, useState } from "react";
import { Container, Badge, Button } from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { GetAllUsers } from "../../requests";
import { format } from 'date-fns';

const formatFirestoreTimestamp = (timestamp) => {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
  return format(date, 'PPpp'); // Example format: 'July 18, 2024 at 12:34 PM'
};

const Users=()=>{
  document.title = "Users | Susipizza";


  const [UsersData,setUsersData]=useState()

 

    const columns = [
        {
            name: <span className="font-weight-bold fs-13">SR No.</span>,
            selector: (row, index) => (index + 1).toString(),
            // sortable: true
          },
          {
            name: <span className="font-weight-bold fs-13">Name</span>,
            selector: (row) => (
              <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                {row.name}
              </div>
            ),
          },
       
        {
          name: <span className="font-weight-bold fs-13">Email ID</span>,
          selector: (row) => (
            <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
              {row.email}
            </div>
          ),
        },
    
        {
          name: <span className="font-weight-bold fs-13">Phone Number</span>,
          selector: (row) => row.phoneNumber,
        },
        {
          name: <span className="font-weight-bold fs-13">Signed up on</span>,
          selector: (row) => (
            <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
              {formatFirestoreTimestamp(row.createdAt)}
            </div>
          ),
        },
        // {
        //   name: <span className="font-weight-bold fs-13">Total Orders</span>,
        //   selector: (row) => row.totalOrders,
        // },
    ]

 


    const Data=async()=>{
      try{
        const res=await GetAllUsers()
        // console.log(res.data)
        setUsersData(res.data)

      }catch(err){
        console.log(err)

        
      }
    }

    useEffect(()=>{
      Data()
    },[])

    return(
        <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Users" />
          <AlternativePagination columns={columns} data={UsersData}/>
        </Container>
      </div>
    </React.Fragment>
    )
}


export default Users