import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  user: null, // Initialize user as null
  role: null, // Initialize role as null
  error: "",
  loading: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
      case LOGIN_SUCCESS:
        // Upon successful login, update user and role in state
        return {
          ...state,
          user: action.payload.user,
          role: action.payload.role,
          loading: false,
        };
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false,user:null,role:null };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
