import React, { useEffect, useState } from "react";
import {
  Container,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { AlternativePagination } from "../../components/Common/Table";
import {
  GetStores,
  GetCategories,
  GetMenu,
  AddMenu,
  DeleteMenu,
  UpdateMenu,
  GetMenuByUserName,
} from "../../requests";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner";
import AWSS3Uploader from "../../components/Common/S3uploader";
import { useSelector } from "react-redux";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Menu = () => {
  document.title = "Menu | Susipizza";

  const [modal, setModal] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [price, setPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [featured, setFeatured] = useState(false);
  const [veg, setVeg] = useState(false);
  const [category, setCategory] = useState("");
  const [smallPrice, setsmallPrice] = useState("");
  const [mediumPrice, setmediumPrice] = useState("");
  const [largePrice, setlargePrice] = useState("");
  const [smallDiscountPrice, setsmallDiscountPrice] = useState("");
  const [mediumDiscountPrice, setmediumDiscountPrice] = useState("");
  const [largeDiscountPrice, setlargeDiscountPrice] = useState("");
  const [stock, setStock] = useState("");
  const [ButtonText, setButtonText] = useState("");
  const [store, setStore] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [Menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);
  const userRole = useSelector((state) => state?.login?.role);
  const username = useSelector((state) => state?.login?.user);

  const toggle = () => {
    setModal(!modal);
    setName("");
    setDescription("");
    setImage("");
    setPrice("");
    setDiscountPrice("");
    setUnit("");
    setFeatured(false);
    setCategory("");
    setsmallPrice("");
    setmediumPrice("");
    setlargePrice("");
    setVeg(false);
    setsmallDiscountPrice("");
    setmediumDiscountPrice("");
    setlargeDiscountPrice("");
    setStock("");
    setSelectedStore("");
    setSelectedMenuId("");
    // checkAdmin();
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const payload = {
        store: selectedStore,
        name: name,
        description: description,
        image: image,
        category: category,
        pizzaPrices: {
          small: {
            price: parseFloat(smallPrice),
            discountPrice: parseFloat(smallDiscountPrice),
          },
          medium: {
            price: parseFloat(mediumPrice),
            discountPrice: parseFloat(mediumDiscountPrice),
          },
          large: {
            price: parseFloat(largePrice),
            discountPrice: parseFloat(largeDiscountPrice),
          },
        },
        price: parseFloat(price),
        discountPrice: parseFloat(discountPrice),
        unit: unit,
        featured: featured,
        stock: stock,
        veg: veg,
      };

      if (ButtonText === "Update") {
        const resp = await UpdateMenu(token, selectedMenuId, payload);
        toast.success("Menu Updated Successfully");
      } else {
        const resp = await AddMenu(token, payload);
        toast.success("Menu Added Successfully");
      }

      toggle();

      MenuData();
    } catch (err) {
      // console.log(err)
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row, index) => (index + 1).toString(),
    },
    {
      name: <span className="font-weight-bold fs-13">foodImage</span>,
      cell: (row) => (
        <img src={row.image} alt="User Photo" width="50" height="50" />
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Store</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.store}
        </div>
      ),
    },

    {
      name: <span className="font-weight-bold fs-13">Name</span>,
      selector: (row) => (
        <div style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
          {row.name}
        </div>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Category</span>,
      selector: (row) => row.category,
    },
    // {
    //   name: <span className="font-weight-bold fs-13">Price</span>,
    //   selector: (row) => row.price,
    // },
    // {
    //   name: <span className="font-weight-bold fs-13">Discount Price</span>,
    //   selector: (row) => row.discountPrice,
    // },
    // {
    //   name: <span className="font-weight-bold fs-13">Unit</span>,
    //   selector: (row) => row.unit,
    // },
    {
      name: <span className="font-weight-bold fs-13">Featured</span>,
      cell: (row) => (
        <Badge color={row.featured ? "success" : "danger"} pill>
          {row.featured ? "Yes" : "No"}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Veg</span>,
      cell: (row) => (
        <Badge color={row.veg ? "success" : "danger"} pill>
          {row.veg ? "Yes" : "No"}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Stock</span>,
      cell: (row) => (
        <Badge color={row.stock === "in stock" ? "success" : "danger"} pill>
          {row.stock}
        </Badge>
      ),
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,

      cell: (row) => (
        <>
          <div className="buttons">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleEdit(row)}
            >
              <span className="mdi mdi-pencil icon-lg"></span>
            </button>
            <button
              className="btn btn-outline-danger mr-2"
              onClick={() => handleDelete(row.id)}
            >
              <span className="mdi mdi-delete icon-lg"></span>
            </button>
          </div>
        </>
      ),
    },
  ];

  const handleDelete = async (itemId) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      await DeleteMenu(token, itemId);

      toast.success("Menu Item deleted successfully");
      MenuData();
    } catch (error) {
      toast.error("Failed to delete menu item");
    } finally {
      setLoading(false);
    }
  };

  const GetstoresData = async () => {
    try {
      const resp = await GetStores();
      // console.log(resp);
      setStore(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetstoresData();
    GetCategoriesData();
    MenuData();
  }, []);

  const MenuData = async () => {
    try {
      setLoading(true);
      if (userRole === "admin") {
        const resp = await GetMenuByUserName(username);
        setMenu(resp.data);
      } else {
        const resp = await GetMenu();
        setMenu(resp.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const GetCategoriesData = async () => {
    try {
      const resp = await GetCategories();
      setCategories(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (rowData) => {
    checkAdmin();
    setModal(true);
    setButtonText("Update");
    setSelectedMenuId(rowData.id);

    // Populate the state variables based on the rowData
    setName(rowData.name);
    setDescription(rowData.description);
    setImage(rowData.image);
    setCategory(rowData.category);
    setSelectedStore(rowData.store);
    setFeatured(rowData.featured);
    setStock(rowData.stock);
    setUnit(rowData.unit);

    setVeg(rowData.veg);

    // Check if the category is Pizza
    if (rowData.category === "Pizza") {
      // Set prices for small, medium, and large pizzas
      setsmallPrice(rowData.pizzaPrices.small.price);
      setsmallDiscountPrice(rowData.pizzaPrices.small.discountPrice);
      setmediumPrice(rowData.pizzaPrices.medium.price);
      setmediumDiscountPrice(rowData.pizzaPrices.medium.discountPrice);
      setlargePrice(rowData.pizzaPrices.large.price);
      setlargeDiscountPrice(rowData.pizzaPrices.large.discountPrice);

      // Clear other price-related fields
      setPrice(""); // Clearing single price for non-pizza items
      setDiscountPrice(""); // Clearing single discount price for non-pizza items
    } else {
      // For non-Pizza items, set the single price and discount price
      setPrice(rowData.price);
      setDiscountPrice(rowData.discountPrice);

      // Clear pizza-related price fields
      setsmallPrice("");
      setsmallDiscountPrice("");
      setmediumPrice("");
      setmediumDiscountPrice("");
      setlargePrice("");
      setlargeDiscountPrice("");
    }
  };

  const getStoreName = (username) => {
    const stored = store.find((storex) => storex.username === username);
    return stored ? stored.name : "";
  };

  const checkAdmin = () => {
    if (userRole === "admin") {
      const selectedStore = getStoreName(username);
      setSelectedStore(selectedStore);
    }
  };

  useEffect(() => {
    checkAdmin();
  }, [userRole, username]);

  return (
    <React.Fragment>
      <LoadingSpinner loading={loading} />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="susipizza" breadcrumbItem="Menu" />
          <button
            type="button"
            class="btn btn-primary mb-3"
            onClick={() => {
              setModal(true);
              checkAdmin();
              setButtonText("Register");
            }}
          >
            Add Food
          </button>
          <AlternativePagination columns={columns} data={Menu} />
        </Container>
      </div>

      {/* Modal */}

      <Modal isOpen={modal} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>
          {ButtonText === "Update" ? "Update Food Item" : "Add Food Item"}
        </ModalHeader>
        <ModalBody className="modal-color">
          <FormGroup>
            <Label for="store">Store</Label>
            <Input
              type="select"
              id="store"
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
              disabled={userRole === "admin"}
            >
              <option value="">Select store</option>
              {store.map((cat, index) => (
                <option key={index} value={cat.name}>
                  {cat.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="category">Category</Label>
            <Input
              type="select"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories.map((cat, index) => (
                <option key={index} value={cat.category}>
                  {cat.category}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="image">Image</Label>
            <AWSS3Uploader onUploadComplete={(e) => setImage(e)} />
            {/* <Input
              type="text"
              id="image"
              value={image}
              onChange={(e) => setImage(e.target.value)}
            /> */}
          </FormGroup>
          {category === "Pizza" && (
            <>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="small">Small Price</Label>
                    <Input
                      type="text"
                      id="small"
                      value={smallPrice}
                      onChange={(e) => setsmallPrice(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="smallDiscount">Small Discount Price</Label>
                    <Input
                      type="text"
                      id="smallDiscount"
                      value={smallDiscountPrice}
                      onChange={(e) => setsmallDiscountPrice(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="medium">Medium Price</Label>
                    <Input
                      type="text"
                      id="meduim"
                      value={mediumPrice}
                      onChange={(e) => setmediumPrice(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="mediumDiscount">Medium Discount Price</Label>
                    <Input
                      type="text"
                      id="mediumDiscount"
                      value={mediumDiscountPrice}
                      onChange={(e) => setmediumDiscountPrice(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="large">Large Price</Label>
                    <Input
                      type="text"
                      id="large"
                      value={largePrice}
                      onChange={(e) => setlargePrice(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="largeDiscount">Large Discount Price</Label>
                    <Input
                      type="text"
                      id="largeDiscount"
                      value={largeDiscountPrice}
                      onChange={(e) => setlargeDiscountPrice(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          {category !== "Pizza" && (
            <>
              <FormGroup>
                <Label for="price">Price</Label>
                <Input
                  type="text"
                  id="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="discountPrice">Discount Price</Label>
                <Input
                  type="text"
                  id="discountPrice"
                  value={discountPrice}
                  onChange={(e) => setDiscountPrice(e.target.value)}
                />
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Label for="unit">Unit</Label>
            <Input
              type="text"
              id="unit"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="featured">Featured</Label>
            <Input
              type="select"
              id="featured"
              value={featured ? "true" : "false"}
              onChange={(e) =>
                setFeatured(e.target.value === "true" ? true : false)
              }
            >
              <option value="">Select Featured</option>
              <option value={true}>YES</option>
              <option value={false}>NO</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="veg">Veg</Label>
            <Input
              type="select"
              id="veg"
              value={veg ? "true" : "false"}
              onChange={(e) =>
                setVeg(e.target.value === "true" ? true : false)
              }
            >
              <option value="">Veg</option>
              <option value={true}>YES</option>
              <option value={false}>NO</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="stock">Stock</Label>
            <Input
              type="select"
              id="stock"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
            >
              <option value="">Select Stock</option>
              <option value="in stock">In Stock</option>
              <option value="out of stock">Out of Stock</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {ButtonText}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Menu;
